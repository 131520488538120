<template>
  <div>
    <!-- {{userData}} -->
    <b-card v-for="zone in zones" :key="zone.id" :title="zone.name">
      <b-row>
        <b-col
          v-for="table in zone.tables"
          :key="table.id"
          md="2"
          style="height: 120px"
        >
          <div class="demo-inline-spacing">
            <!-- <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              :text="resolveTableName(table)"
              :variant="resolveTableVariant(table.status)"
              size="lg"
              style="height: 100px; width: 100%"
              :disabled="table.isDisabled"
            >
              <b-dropdown-item
                v-for="menuItem in table.menusData"
                :key="menuItem.value"
                @click="resolveTableStatus(table, menuItem.value)"
              >
                {{ menuItem.text }}
              </b-dropdown-item>
            </b-dropdown> -->

            <b-button-group size="lg">
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :text="resolveTableName(table)"
                :variant="resolveTableVariant(table.status)"
                style="height: 100px; width: 100%"
                right
                :disabled="table.isDisabled"
              >
                <b-dropdown-item
                  v-for="menuItem in table.menusData"
                  :key="menuItem.value"
                  @click="resolveTableStatus(table, menuItem.value)"
                >
                  {{ menuItem.text }}
                </b-dropdown-item>

                <b-dropdown-item
                  @click="sendInfo(table, userData.branchId)"
                  v-b-modal.modal-lg
                >
                  Move Order
                </b-dropdown-item>
                
                <b-dropdown-item
                  @click="sendInfo(table, userData.branchId)"
                  v-b-modal.modal-tb
                >
                  Move Table
                </b-dropdown-item>
              </b-dropdown>

              <b-dropdown
                v-if="table.isSplit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :text="table.splitTable.name"
                :variant="resolveTableVariant(table.splitTable.status)"
                style="height: 100px; width: 100%"
                right
              >
                <b-dropdown-item
                  v-for="menuItem in table.splitTable.menusData"
                  :key="menuItem.value"
                  @click="resolveTableStatus(table.splitTable, menuItem.value)"
                >
                  {{ menuItem.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>

            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="resolveTableVariant(table.status)"
              @click="checkActiveStatus(table)"
              @contextmenu.prevent="openMenu(table)"
            >
              {{ table.name }}
            </b-button>

            <vue-context :ref="table.contextMenuName">
              <li>
                <b-link
                  v-for="menuItem in table.menusData"
                  :key="menuItem.value"
                  class="d-flex align-items-center"
                  @click="resolveTableStatus(table, menuItem.value)"
                >
                  <span class="ml-75">{{ menuItem.text }}</span>
                </b-link>
              </li>
            </vue-context> -->
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- <vue-context ref="menuProp">
      <li>
        <b-link
          v-for="menuItem in menuData"
          :key="menuItem.value"
          class="d-flex align-items-center"
        >
          <span class="ml-75">{{ menuItem.text }}</span>
        </b-link>
      </li>
    </vue-context> -->
    <b-modal
      id="modal-lg"
      ref="my-modal"
      ok-only
      ok-title="ปิด"
      @hidden="closeModal"
      :user="'user'"
      centered
      size="lg"
      title="ย้ายจากโต๊ะ"
      hide-footer
    >
      <div>
        <h2>โต๊ะต้นทาง {{ fromtable.name }}</h2>
        <h4>ย้ายไป</h4>
        <b-row>
          <b-col md="9">
            <b-form-select v-model="totableid">
              <option :value="null">-- กรุณาเลือกโต๊ะปลายทาง --</option>
              <option
                v-for="option in totableLists"
                :value="option"
                v-if="fromtable.id != option.value"
              >
                {{ option.name }}
              </option>
            </b-form-select>
          </b-col>
          <b-col md="3">
            <b-button
              type="button"
              @click="getMoveItem(fromtable.id, totableid.value)"
              >ยืนยัน</b-button
            >
          </b-col>
        </b-row>
        <br />
        <b-row>
          <!-- people group 1 -->
          <b-col md="6">
            <h6 class="text-primary font-weight-bold mb-2">
              Table {{ fromtable.name }}
            </h6>

            <!-- draggable -->
            <draggable
              :list="tablefrom"
              tag="ul"
              group="people"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(listItem, index) in tablefrom"
                :key="index"
                tag="li"
              >
                <div class="d-flex">
                  <!-- <b-avatar :text="listItem.menu.slice(0,2)" /> -->
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.menu }}
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-col>

          <!-- people group 2 -->
          <b-col md="6" class="mt-sm-2 mt-md-0">
            <h6 class="text-primary font-weight-bold mb-2">
              Table {{ totableid.name }}
            </h6>

            <!-- draggable -->
            <draggable
              :list="tableto"
              tag="ul"
              group="people"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(listItem, index) in tableto"
                :key="index"
                tag="li"
              >
                <div class="d-flex">
                  <!-- <b-avatar :text="listItem.menu.slice(0,2)" /> -->
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.menu }}
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-col>

          <!-- people group 1 code -->
          <!-- <b-col md="6" class="mt-1">
            <prism language="javascript" class="rounded">
              Table A1 : {{ tablefrom }}
            </prism>
          </b-col> -->

          <!-- people group 2 code -->
          <!-- <b-col md="6" class="mt-1">
            <prism language="javascript" class="rounded">
              Table A2: {{ tableto }}
            </prism>
          </b-col>-->
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button type="button" class="mt-2 mr-1" @click="closeModal()"
              >ยกเลิก</b-button
            >
            <b-button
              type="button"
              class="mt-2"
              @click="setMoveItem(fromtable.id, totableid.value)"
              >บันทึก</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>

    
    <b-modal
      id="modal-tb"
      ref="my-modal-tb"
      ok-only
      ok-title="ปิด"
      @hidden="closeModal"
      :user="'user'"
      centered
      size="lg"
      title="ย้ายจากโต๊ะ"
      hide-footer
    >
      <div>
        <h2>โต๊ะต้นทาง {{ fromtable.name }}</h2>
        <h4>ย้ายไป</h4>
        <b-row>
          <b-col md="9">
            <b-form-select v-model="totableid">
              <option :value="null">-- กรุณาเลือกโต๊ะปลายทาง --</option>
              <option
                v-for="option in totableLists"
                :value="option"
                v-if="fromtable.id != option.value"
              >
                {{ option.name }}
              </option>
            </b-form-select>
          </b-col>
          <b-col md="3">
            <b-button
              type="button"
              @click="getMoveItem(fromtable.id, totableid.value)"
              >ยืนยัน</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button type="button" class="mt-2 mr-1" @click="closeModal()"
              >ยกเลิก</b-button
            >
            <b-button
              type="button"
              class="mt-2"
              @click="setMoveTable(fromtable.id, totableid.value)"
              >ย้ายทั้งโต๊ะ</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  VBModal,
  BLink,
  BButton,
  BCol,
  BRow,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButtonGroup,
  BListGroupItem,
} from "bootstrap-vue";
import VueContext from "vue-context";
import Ripple from "vue-ripple-directive";
import { ref, onUnmounted } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
import orderHomeStoreModule from "./orderHomeStoreModule";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import { codeBlank } from "../components/image/code";

export default {
  components: {
    BModal,
    BButton,
    BCol,
    BRow,
    BDropdown,
    BDropdownItem,
    BCard,
    VueContext,
    BLink,
    BButtonGroup,
    BListGroupItem,
    draggable,
    Prism,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isVerticalMenuCollapsed:
        this.$store.state.verticalMenu.isVerticalMenuCollapsed,
      isReadyToMerge: false,
      tableMergeSelect: null,
      tablefrom: [],
      tableto: [],
      totableLists: [],
      totableid: [],
      fromtable: [],
      selectedUser: "",
    };
  },
  setup() {
    const OrderHomeStoreModuleName = "orderHome";

    // Register module
    if (!store.hasModule(OrderHomeStoreModuleName))
      store.registerModule(OrderHomeStoreModuleName, orderHomeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OrderHomeStoreModuleName))
        store.unregisterModule(OrderHomeStoreModuleName);
    });

    const menuData = [{ value: "open", text: "Open" }];

    // const menuData = [
    //   { value: 'open', text: 'Open' },
    //   { value: 'split', text: 'Split' },
    //   { value: 'merge', text: 'Merge' },
    //   { value: 'reserved', text: 'Reserve' },
    // ]

    const userData = getUserData();
    const tablesStatus = ref([]);

    store
      .dispatch("orderHome/getStatusTables", { id: userData.branchId })
      .then((res) => {
        if (res !== null) {
          console.log("GetAllwithStatus", res.data);
          tablesStatus.value = res.data;
        }
      })
      .catch((error) => {});

    const zones = ref([
      // {
      //   id: 1,
      //   name: 'Indoor Zone',
      //   totalTables: 12,
      //   tables: [],
      // },
      // {
      //   id: 2,
      //   name: 'VIP Zone',
      //   totalTables: 6,
      //   tables: [],
      // },
      // {
      //   id: 3,
      //   name: 'Delivery',
      //   totalTables: 3,
      //   tables: [],
      // },
    ]);

    store
      .dispatch("orderHome/getTableGroups", { id: userData.branchId })
      .then((response) => {
        console.log("response.data", response.data);
        if (response !== null) {
          response.data.forEach((tableGroup) => {
            zones.value.push({
              id: tableGroup.id,
              name: tableGroup.table_group,
              tables: [],
            });
          });

          store
            .dispatch("orderHome/getTables", { id: userData.branchId })
            .then((response2) => {
              console.log("response2.data", response2.data);
              if (response2 !== null) {
                zones.value.forEach((zone) => {
                  const tables = response2.data.filter(
                    (item) => item.table_group_id === zone.id
                  );

                  tables.forEach((table) => {
                    const tableStatus = tablesStatus.value.filter(
                      (item) =>
                        item.table_group_id === zone.id &&
                        item.table_id === table.id
                    );

                    zone.tables.push({
                      zoneId: zone.id,
                      id: table.id,
                      name: table.table_name,
                      order_number: table.order_number,
                      seq: table.table_seq,
                      minSeat: table.min_seat,
                      maxSeat: table.max_seat,
                      order_number: table.order_number,
                      status:
                        tableStatus.length > 0
                          ? tableStatus[0].table_status
                          : "",
                      menusData: menuData,

                      // contextMenuName: `menu${table}`,
                      // merge
                      isMerge: false,
                      mergeTable: null,
                      mergeName: "",
                      isDisabled: false,
                      // split
                      isSplit: false,
                      // splitName: '',
                      splitTable: {
                        name: `${table.table_name} - 2`,
                        status: "available",
                        menusData: [
                          { value: "open", text: "Open" },
                          { value: "reserved", text: "Reserve" },
                        ],
                      },
                    });
                  });
                });
              }
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {});

    // zones.value.forEach(zone => {
    //   for (let table = 1; table <= zone.totalTables; table++) {
    //     zone.tables.push({
    //       zoneId: zone.id,
    //       id: table,
    //       name: zone.name === 'VIP Zone' ? `V ${table}` : `T ${table}`,
    //       status: 'available',
    //       menusData: menuData,
    //       // contextMenuName: `menu${table}`,
    //       // merge
    //       isMerge: false,
    //       mergeTable: null,
    //       mergeName: '',
    //       isDisabled: false,
    //       // split
    //       isSplit: false,
    //       // splitName: '',
    //       splitTable: {
    //         name: zone.name === 'VIP Zone' ? `V ${table} - 2` : `T ${table} - 2`,
    //         status: 'available',
    //         menusData: [
    //           { value: 'open', text: 'Open' },
    //           { value: 'reserve', text: 'Reserve' },
    //         ],
    //       },
    //     })
    //   }
    // })

    return {
      zones,
      menuData,
      userData,
    };
  },
  created() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },
  destroyed() {
    this.$store.commit(
      "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
      this.isVerticalMenuCollapsed
    );
  },
  methods: {
    showToast(variant, position, timeout, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",

            text,
            variant,
          },
        },
        {
          position,
        },
        {
          timeout,
        }
      );
    },
    resolveTableVariant(status) {
      switch (status) {
        case "available":
          return "outline-success";
        case "occupied":
          return "outline-danger";
        case "reserved":
          return "outline-secondary";
        case "pending":
          return "outline-warning";
        // case 'split':
        //   return 'outline-dark'
        case "merge":
          return "outline-dark";
        default:
          return "outline-secondary";
      }
    },
    resolveTableStatus(item, status) {
      console.log(item);
      switch (status) {
        case "open":
          // item.status = status
          router.push({
            name: "order-ordering",
            params: {
              id: item.id,
              name: item.name,
              status: item.status,
              order_number: item.order_number,
            },
          });
          break;
        case "split":
          item.isSplit = true;
          item.menusData = [
            { value: "open", text: "Open" },
            { value: "unsplit", text: "Unsplit" },
            { value: "reserved", text: "Reserve" },
          ];
          break;
        case "unsplit":
          item.status = "available";
          item.splitTable.status = "available";
          item.isSplit = false;
          item.menusData = this.menuData;
          break;
        case "merge":
          item.status = status;

          if (this.isReadyToMerge) {
            // merge table ===============================
            if (this.tableMergeSelect !== null) {
              this.tableMergeSelect.mergeTable = item;
              this.tableMergeSelect.mergeName = `${this.tableMergeSelect.name} + ${item.name}`;
              // eslint-disable-next-line no-param-reassign
              item.isDisabled = true;
              this.isReadyToMerge = false;
              this.tableMergeSelect = null;
            }
          } else {
            // set merge table ==========================
            // eslint-disable-next-line no-param-reassign
            item.isMerge = true;
            this.isReadyToMerge = true;
            this.tableMergeSelect = item;
            this.tableMergeSelect.menusData = [
              { value: "open", text: "Open" },
              { value: "unmerge", text: "Unmerge" },
              { value: "reserved", text: "Reserve" },
            ];
            this.showToast(
              "danger",
              "top-center",
              1000000,
              "Please select table that want to merge/change."
            );
          }
          break;
        case "unmerge":
          item.status = "available";
          item.isMerge = false;
          item.mergeName = "";
          item.menusData = this.menuData;
          if (item.mergeTable !== null) {
            item.mergeTable.isDisabled = false;
            item.mergeTable.status = "available";
            item.mergeTable = null;
          }
          this.isReadyToMerge = false;
          this.tableMergeSelect = null;
          break;
        case "reserved":
          item.status = status;

          store
            .dispatch("orderHome/reserveTable", { id: item.id })
            .then((res) => {
              if (res !== null) {
                console.log("reserveTable", res);
              }
            })
            .catch((error) => {});

          break;
        default:
          break;
      }
    },
    resolveTableName(item) {
      if (item.mergeName !== "") {
        return item.mergeName;
      }
      return item.name;
    },

    getMoveItem(id1, id2) {
      store
        .dispatch("orderHome/getBetween", { id1: id1, id2: id2 })
        .then((res) => {
          if (res !== null) {
            this.tablefrom = res.data[0].order_detail_list;
            this.tableto = res.data[1].order_detail_list;

            console.log("tablefrom", this.tablefrom);
            console.log("tableto", this.tableto);
          }
        })
        .catch((error) => {});
    },
    setMoveItem(id1, id2) {
      store
        .dispatch("orderHome/setMoveItem", {
          id1: id1,
          id2: id2,
          a1: this.tablefrom,
          a2: this.tableto,
        })
        .then((res) => {
          if (res !== null) {
            console.log(res);
            this.tablefrom = [];
            this.tableto = [];
            this.totableLists = [];
            this.totableid = [];
            this.$refs["my-modal"].hide();
            // this.getMoveItem(id1, id2);
          }
        })
        .catch((error) => {});
    },
    setMoveTable(id1, id2) {
      store
        .dispatch("orderHome/setMoveTable", {
          id1: id1,
          id2: id2,
          a1: this.tablefrom,
          a2: this.tableto,
        })
        .then((res) => {
          if (res !== null) {
            console.log(res);
            this.tablefrom = [];
            this.tableto = [];
            this.totableLists = [];
            this.totableid = [];
            this.$refs["my-modal-tb"].hide();
            // this.getMoveItem(id1, id2);
          }
        })
        .catch((error) => {});
    },

    sendInfo(tableId, branchId) {
      this.fromtable = tableId;
      console.log(this.fromtable);
      this.getAllTable(tableId, branchId);
    },
    getAllTable(tableId, branchId) {
      store
        .dispatch("orderHome/getAllTable", { branch_id: branchId })
        .then((res) => {
          if (res !== null) {
            console.log(res);
            this.totableLists = res.data;
          }
        })
        .catch((error) => {});
    },
    closeModal() {
      this.tablefrom = [];
      this.tableto = [];
      this.totableLists = [];
      this.totableid = [];
      this.$refs["my-modal"].hide();
      // this.getMoveItem(id1, id2);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-context.scss";
</style>
