import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getTableGroups(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbTableGroup/GetAllbyBranch', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTables(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbTable/GetAllbyBranch', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStatusTables(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbTable/GetAllwithStatus', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reserveTable(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://posapi.owlstime.com/api/FnbTable/ReserveTable', {
            params: {
              table_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getBetween(ctx, {id1,id2}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://posapi.owlstime.com/api/FnbOrder/GetOrderTransfer?Table_Id1=${id1}&Table_Id2=${id2}`, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setMoveItem(ctx, {id1,id2,a1,a2}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`https://posapi.owlstime.com/api/FnbOrder/TransferItem`, { 
            "table_id_1": id1,
            "transfer_item_1": a1,
            "table_id_2": id2,
            "transfer_item_2": a2
          }
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setMoveTable(ctx, {id1,id2}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`https://posapi.owlstime.com/api/FnbOrder/TransferAll`, { 
            "table_id_1": id1,
            "table_id_2": id2
          }
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllTable(ctx, {branch_id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://posapi.owlstime.com/api/FnbTable/GetOccupiedTable?branch_id=${branch_id}`
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
